export const WTB_HEADER_TEXT_1 = `Authentic flavor.`;
export const WTB_HEADER_TEXT_2 = `Unique taste.`;
export const WTB_PARA_TEXT = `With quality ingredients and timeless methods, our age-old
process gives our meats an authentic flavor, perfect for any
occasion.`;
export const WTB_BUTTON_TEXT = `Where To Buy`;
export const PRODUCT_HEADER_TEXT = `Salame & Pepperoni`;
export const PRODUCT_BUTTON_TEXT = `Our Products`;
export const RECIPES_HEADER_TEXT_1 = `Savor the flavor of gallo salame`;
export const RECIPES_HEADER_TEXT_SUP = `®`;
export const RECIPES_HEADER_TEXT_2 = `today!`;
export const RECIPES_PARA_TEXT = `Enjoy it in a sandwich, on a pizza, or all by itself. Check out
our recipes for inspiration!`;
export const RECIPES_BUTTON_TEXT = `EXPLORE RECIPES`;
export const STORY_HEADER_TEXT = `Over 100 Years In the Making`;
export const STORY_PARA_TEXT = `Our authentic Italian flavor comes from our use of high-quality
meats, delicious spice blends, and the temperate San Francisco Bay area climate, ideal for curing.`;
export const STORY_BUTTON_TEXT = `Read Our story`;
